*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

body {
  background-color: #fbfbfb;
  line-height: 1;
}

p {
  font-size: 1rem !important;
}

.shadowed {
  box-shadow: 1px 2px 4px 0 #ced4da; }

.full-width {
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%; }

.badge {
  padding: .5rem;
  margin: 0px .5rem;
  color: #fff;
  font-size: .8rem; }

.btn {
  font-weight: 500;
  color: #f7f7f7;
  background-color: rgba(0, 122, 188, 0.8);
  border-radius: 2px;
  box-shadow: 2px 2px 1px rgba(34, 34, 34, 0.2);
  transition: all .1s;
  padding: .3rem 1rem; }
  .btn:hover:not(:disabled) {
    box-shadow: 3px 3px 5px rgba(34, 34, 34, 0.5);
    color: #f7f7f7; }
  .btn:disabled {
    cursor: default;
    background-color: #59aa7c;
    color: #f7f7f7; }
  .btn:active, .btn:focus {
    transform: translateY(2px);
    box-shadow: x 3px 2px rgba(34, 34, 34, 0.5); }
  .btn svg {
    margin-right: .5rem;
    margin-bottom: 2px;
  }
  .btn--gray {
    background-color: #666666; }
  .btn--secondary {
    background-color: #00b050; }
  .btn--confirm {
    background-color: #00b050; }
  .btn-success {
    background-color: #00b050; }
  .btn--tertiary {
    background-color: #ffb900; }
  .btn.red {
    background-color: #960018;
    color:white; }
  .btn--rounded {
    border-radius: .5rem; }
  .btn--cancel {
    background-color: #960018; }
  .btn--pricing-setup {
    margin-left: 1rem;
    margin-bottom: 0.3rem;
  }
.card--primary .card-header {
  text-align: center;
  color: #fff;
  background-color: #666666; }

.section-card.active {
  border-left: 5px solid #00b050; }

.form .collapsible__trigger {
  background-color: #666666;
}

.collapsible {
  transition: max-height .2s ease-in-out;
  width: 100%;
  height: 100%; }
  .collapsible__trigger {
    display: flow-root; }
    .collapsible__trigger:hover {
      cursor: pointer;
      background-color: rgba(206, 212, 218, 0.5); }
  .collapsible__content.hidden {
    max-height: 0px;
    overflow: hidden; }

.menu {
  display: inline-block;
  position: relative; }
  .menu__trigger {
    background-color: #00b050;
    color: #fff;
    height: 2.2rem; }
    .menu__trigger .bars {
      margin-right: .5rem; }
  .menu__item-list {
    margin: 0;
    padding: 0;
    list-style: none; }
  .menu__item {
    padding: .3rem 1rem;
    white-space: nowrap;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    background: linear-gradient(to right, #a7cdf1 50%, transparent 50%);
    background-size: 200%;
    background-position: 100%;
    transition: all .2s;
    border-bottom: 2px solid transparent; }
    .menu__item:hover {
      background-position: 0;
      border-bottom: 2px solid #7bb4ea; }
    .menu__item__icon {
      margin-right: 1rem; }
    .menu__item__link:link, .menu__item__link:visited {
      display: block;
      text-decoration: none;
      color: currentColor; }
    .menu__item__link:active {
      transform: translateY(1px); }
  .menu__content, .menu__submenu__content {
    background-color: #fff;
    min-width: 15rem;
    position: absolute;
    border: 1px solid #ced4da;
    box-shadow: 0 1rem 2rem rgba(34, 34, 34, 0.2);
    transition: all .2s;
    top: 2.2rem;
    left: 0; }
    .menu__content.closed, .menu__submenu__content.closed {
      opacity: 0; }
    .menu__content.opened, .menu__submenu__content.opened {
      opacity: 1;
      z-index: 100; }
  .menu__submenu {
    position: relative; }
    .menu__submenu__trigger {
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .menu__submenu__content {
      top: 0;
      left: 100%; }
  .menu__content.opened::before, .menu__submenu__content.opened::before {
    content: "";
    width: calc(100% + 2rem);
    height: calc(100% + 2rem);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transform: translate(-1rem, -1rem); }

.react-datepicker-wrapper {
  width: 100%; }

.react-datepicker-inline {
  width: 110px;
}
.react-datepicker-popper {  z-index: 9999999 !important }

.form {
  background: #fff;
  padding-bottom: 0.5rem; }
  .form-row {
    padding: 0 1rem;
    position: relative;
    margin: .5rem 0; }
  .form-title {
    text-transform: uppercase;
    padding-left: 1rem;
    margin: .6rem 0; }
  .form-section-title {
    background-color: #a7cdf1;
    padding: .5rem 2rem;
    margin-bottom: 1em;
    border: 1px solid #ced4da; }
  .form-control {
    border-radius: 0;
    border: 1px solid #ced4da; }
  .form-label {
    margin-bottom: 5px; }
    .form-label__label {
      padding: 2px .5rem;
      background-color: #666666;
      color: #fff;
      border-radius: 2px;
      box-shadow: 1px 1px 1px rgba(34, 34, 34, 0.3); }
    .form-label__required {
      margin-left: 5px;
      color: red; }
  .form .btn[type=submit],
  .form .clear-filters {
    margin-left: 2rem; }
  .form .btn[type=submit] {
    background-color: #00b050; }
  .form .clear-filters {
    background-color: #777; }
  .form-control.is-valid, .form .was-validated .form-control:valid {
    background-position: right calc(1em + .1875rem) center;
    border: 1px solid #ced4da; }
  .form-control.is-invalid, .form .was-validated .form-control:invalid {
    background-position: right calc(1em + .1875rem) center; }
  .form .collapsible__trigger {
    background-color: #666666;
    color: white; }
  .form .collapsible__content:not(.hidden) {
    margin-top: 1rem;
  }
  .form .form-row .ck {
    width: 100%; }

.tableFixed {
  overflow: auto;
  max-height: 78vh;
  display: block;
}

.tableFixed thead th {
  text-align: center;
}

.fundersListFixed {
  overflow: auto;
  max-height: 69.1vh;
  display: block;
}

.funderCasesFixed {
  overflow: auto;
  max-height: 72.8vh;
  display: block;
}

.tableFixedNotPaginated {
  overflow: auto;
  max-height: 73.4vh;
  display: block;
}

.tableFixed thead, .tableFixedNotPaginated thead, .fundersListFixed thead, .funderCasesFixed thead {
  position: sticky;
  top: 0;
  z-index: 1;
}


.table [role=columnheader] {
  background-color: #666666;
  color: white;
  position: relative; }
  .table [role=columnheader] .sort-indicator {
    position: absolute;
    right: 0; }

.table [role=row].active {
  background-color: #d4e8d6; }

.table [role=cell] {
  white-space: nowrap; }
  .table [role=cell].wrap-text {
    white-space: normal; }
  .table [role=cell].action {
    width: 116px; }

.table--action-icon {
  height: 1.3rem;
  width: 1.3rem;
  margin-left: 5px;
  color: #666666;
  cursor: pointer;
}

.table--action-icon-tooltip {
  width: 1.3rem;
  margin-left: 10px;
  color: #666666;
}

.table--action-icon.btn--red {
  color: #960018;
}

.table--action-icon.btn--green {
  color: #00b050;
}

.table tbody td {
  vertical-align: middle;
}

.table tbody.empty td {
  color: #777;
  text-align: center;
  padding: .5rem 0;
  background-color: #f7f7f7; }

.pagination {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(max-content, 0));
  grid-gap: .3rem;
  margin-bottom: 1rem; }
  .pagination .control {
    color: #00b050;
    background-color: #fff;
    padding: 0 4px;
    border: 1px solid #ced4da;
    min-width: 2rem;
    box-shadow: 0 2px 2px rgba(34, 34, 34, 0.1);
    transition: all .2s; }
    .pagination .control.active {
      color: #fff;
      background-color: #00b050; }
    .pagination .control:hover:not(:disabled) {
      transform: translateY(-1px);
      box-shadow: 0 3px 3px rgba(34, 34, 34, 0.2); }
    .pagination .control:focus {
      outline: none; }
    .pagination .control:disabled {
      color: #777;
      box-shadow: none; }
  .pagination .page-size {
    grid-row: 2 / 3;
    grid-column: 1 / span 2;
    border: 1px solid #ced4da;
    background-color: #fff;
    color: #00b050;
    cursor: pointer; }

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  background-color: #666666;
  padding: .5rem 1rem;
  border-bottom: 2px solid gray;
  color: #f3f3f3; }
  .table-header .title {
    display: inline-block;
    font-size: 1.3rem;
    margin: 0; }
  .table-header .actions button {
    font-size: .8rem;
    padding: 4px 8px;
    text-transform: inherit; }
    .table-header .actions button:not(:last-of-type) {
      margin-right: 1rem; }

.records-table {
  min-width: 100%;
  width: fit-content; }

.loader {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border: 3px solid #00b050;
  border-top: 3px solid #fff;
  border-radius: 50%;
  margin-right: .5rem;
  animation: spin 1s linear infinite; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.modal__header {
  text-align: center;
  font-size: 1.4rem;
  margin: 0; }

.modal--confirm {
  z-index: 9999;
}

.modal--confirm .modal__footer {
  justify-content: space-between; }

.modal-background {
  background-color: rgba(206, 212, 218, 0.8);
  z-index: 9999;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%; }

.full-page-modal.wide.container {
  max-width: 90%;
}
.full-page-modal {
  position: relative;
  max-height: 93vh;
  margin: 2rem auto;
  padding: 1.5rem;
  border: 1px solid #ced4da;
  border-radius: 0.5rem;
  outline: none;
  background: #fff;
  overflow: auto; }
  .full-page-modal .close-btn {
    position: absolute;
    top: 1rem;
    right: 1.3rem;
    height: 2rem;
    width: 2rem;
    font-size: 1rem;
    color: #777;
    cursor: pointer;
    border: 1px solid #ced4da;
    border-radius: 50%;
    padding-top: 2px;
    padding-left: 2px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .full-page-modal .close-btn:hover {
      box-shadow: 0px 2px 2px rgba(119, 119, 119, 0.2);
      font-weight: 500; }
  .full-page-modal .content {
    padding: .5rem; }

.tab-group {
  width: max-content;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(max-content, 0)); }
  .tab-group .tab {
    text-decoration: none;
    color: #fff;
    font-size: 1rem;
    padding: .5rem 1rem;
    transition: all .3s; }
    .tab-group .tab.active, .tab-group .tab:hover {
      color: white;
      background-color: #b7b7b7; }

span.data-grid-container table.data-grid tbody tr td.cell {
  padding: 2px 1rem 0 1rem !important; }

span.data-grid-container table.data-grid tbody tr td.cell.title {
  background-color: #a7cdf1;
  color: #222222; }

span.data-grid-container table.data-grid tbody tr td.cell.selected.editing input.data-editor {
  height: 100% !important; }

span.data-grid-container table.data-grid tbody tr td.cell[data-error="true"] {
  background-color: red; }

.scrollspy {
  position: fixed; }
  .scrollspy li {
    list-style: none; }
  .scrollspy .title {
    padding: 3px; }
  .scrollspy .item {
    padding: 2px 5px;
    border-left: 4px solid #f7f7f7;
    transition: .3s all; }
    .scrollspy .item.active {
      border-left: 4px solid #ffb900;
      font-weight: 500; }
    .scrollspy .item:hover {
      cursor: pointer; }

.tf-condition {
  margin: 0;
  border: 1px solid #ced4da;
  padding: 1rem 2rem; }
  .tf-condition__info {
    display: flex;
    justify-content: space-between;
    width: 95%; }
    .tf-condition__info__name {
      font-weight: 500; }
    .tf-condition__info label {
      cursor: pointer; }
      .tf-condition__info label input {
        margin-left: .5rem; }
  .tf-condition .remove-item {
    background-color: #ffb900;
    border: none;
    color: #fff;
    padding: 0 6px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    margin-right: .5rem;
    border-radius: 50%; }
    .tf-condition .remove-item:focus {
      outline: 1px solid #ced4da; }

.new-condition, .new-factor {
  width: 50%;
  margin: 1rem 2rem; }

.factor {
  width: 95%;
  display: flex; }
  .factor input {
    height: 2rem;
    color: inherit;
    margin-left: 2rem;
    padding: 2px 5px;
    border-radius: 2px;
    border: none;
    border-bottom: 1px solid #ced4da;
    display: inline-block;
    width: 100%; }
    .factor input:focus {
      outline: none;
      border-bottom: 1px solid #00b050; }
    .factor input::-webkit-input-placeholder {
      color: #474747; }
  .factor__notes {
    flex-grow: 1; }

.file-display {
  display: grid;
  grid-template-columns: 3rem 1fr;
  grid-column-gap: .5rem;
  align-items: center;
  border: 1px solid #ced4da;
  width: fit-content;
  height: 91px;
  padding: 5px 10px;
  margin-bottom: 1rem; }
  .file-display > * {
    padding: 5px; }
  .file-display:hover {
    background-color: #f7f7f7; }
  .file-display .file-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .file-display .file-details {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 5px; }
  .file-display .size-and-actions {
    display: flex;
    justify-content: space-between;
    align-items: center; }

.dropzone {
  display: grid;
  justify-items: center;
  align-items: center;
  border: 2px dashed #777;
  height: 5rem;
  margin-bottom: 1rem; }
  .dropzone.active {
    border: 2px dashed #00b050;
    background-color: #f7f7f7; }

.emails-viewer {
  display: grid;
  grid-template-rows: 1;
  grid-template-columns: [emails-list] minmax(350px, 30%) [email-detail] 1fr;
  width: 100%;
  height: 30rem;
  border: 1px solid #ced4da; }
  .emails-viewer .emails-list {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #ced4da;
    overflow-y: auto; }
  .emails-viewer .email-list-item {
    display: flex;
    flex-direction: column;
    height: 4rem;
    padding: .5rem 1rem;
    border: 1px solid #f7f7f7; }
    .emails-viewer .email-list-item:hover {
      cursor: pointer;
      background-color: #f2f2f2; }
    .emails-viewer .email-list-item.active {
      border-left: 4px solid #00b050; }
    .emails-viewer .email-list-item .subject {
      font-weight: bold; }
    .emails-viewer .email-list-item .details {
      display: flex;
      justify-content: space-between; }
      .emails-viewer .email-list-item .details .email-actions {
        display: flex; }
  .emails-viewer .email-content {
    white-space: pre-wrap;
    padding: 1rem;
    overflow-y: auto; }
  .emails-viewer .attachment {
    display: flex;
    padding: 5px 10px;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ced4da;
    /* add margin to the last attachment */ }
    .emails-viewer .attachment:hover {
      border-left: 2px solid #00b050; }
    .emails-viewer .attachment:last-of-type {
      margin-bottom: 1rem; }

.action-icons {
  display: flex;
  align-items: center; }
  .action-icons .icon {
    display: inline;
    padding: 4px; }
    .action-icons .icon:hover {
      color: #00b050;
      font-weight: bold;
      cursor: pointer;
    }
    .red {
      color: #960018;
    }
    .green {
      color: #237100;
    }

#create-new-case {
  margin-bottom: 1rem; }

.form {
  margin-bottom: 1rem; }

.sections {
  display: grid;
  row-gap: 2rem; }

.section-card {
  overflow: hidden; }
  .section-card dl {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 2rem;
    margin: 0; }
    .section-card dl dt, .section-card dl dd {
      display: inline-block; }
    .section-card dl dt {
      margin-right: .5rem;
      font-weight: 500; }
    .section-card dl dd {
      font-weight: 300; }
    .section-card dl dt::after {
      content: ":"; }
  .section-card .card-header {
    background-color: #666666;
    border: none;
    border-bottom: 1px solid #f7f7f7;
    padding: .5rem 1rem;
    display: flex;
    justify-content: space-between; }
  .section-card .card-footer {
    background-color: #fff;
    padding: .5rem 1rem;
    border: none;
    border-top: 1px solid #f7f7f7; }
    .section-card .card-footer .btn {
      padding: .1rem .5rem;
      margin-right: 1rem; }
  .section-card .card-body {
    padding: 1rem 1rem 0 1rem; }
  .section-card .section-title {
    color: #fff;
    margin: 0;
    margin-right: 5rem; }
  .section-card .section-status {
    color: #fff;
    text-align: center;
    font-weight: 500;
    padding: 0 .5rem; }
    .section-card .section-status--completed {
      background-color: #7BAE7F; }
    .section-card .section-status--pending {
      background-color: #ffb900; }
    .section-card .section-status--empty {
      background-color: #777; }

.tab-group {
  transform: translateY(1rem); }

.case-summary {
  margin-bottom: 3rem; }
  .case-summary td {
    padding: .3rem 1rem;
    font-weight: 300; }
    .case-summary td:first-child {
      font-weight: 400; }
  .case-summary tr {
    border-bottom: 1px solid #ced4da; }
    .case-summary tr:first-child {
      border-top: 1px solid #ced4da; }

.section-card {
  margin-bottom: 1rem; }

@media only screen and (max-width: 1570px) {
  .scrollspy {
    display: none; } }

.container-fluid {
  overflow-x: hidden;
  flex: 1 0 auto;
  margin-top: 5rem;
}

.container-fluid.login {
  margin-top: 0;
  background-image: url('/src/logo-background.png');
  background-size: 60%;
  background-position: center;
  background-repeat: no-repeat;
}

.login-page {
  display: grid;
  grid-template-columns: minmax(min-content, 800px);
  justify-content: center;
  align-content: center;
  height: 90vh;
  position: relative;
}

.login-page.reset {
  margin-top: -1rem;
  grid-template-columns: minmax(min-content, 500px);
}

.login-container {
  padding: 60px 150px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: rgba(255,255,255,0.5);
}

.login-page .logo-top {
  display: block;
  margin: 0 auto 20px;
  width: 150px;
}

.login-page .form-container {
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  position: relative;
  z-index: 1;
}

.login-page .form-title {
  text-align: center;
  padding: 2rem;
  background-color: black;
  color: #fff;
  margin: 0;
}

.login-page .login-form {
  padding: 2rem;
  background-color: white;
}

.login-page .login-form .input {
  margin-bottom: 2rem;
}

.login-page .login-btn {
  display: block;
  width: 100%;
  margin-top: 2.5rem;
  margin-bottom: 2rem;
  background-color: #00b050;
  text-transform: uppercase;
}

.login-page .reset-password {
  font-size: 13px;
  font-weight: 500;
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #212529;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
}

.login-page .error {
  margin: 1rem 0;
  color: #960018;
  font-weight: 500;
}

.reset-password-description {
  padding: 0 1rem 2rem;
  position: relative;
}

.login-page .ssl-logo-right {
  float: right;
  margin-top: 10px;
  width: 120px;
}

.navbar {
  background-color: #fff; }

.header.primary {
  margin-bottom: 2rem;
  color: #fff;
  padding: 1rem 0;
  background-color: #00b050;
  box-shadow: 0 2px 2px rgba(34, 34, 34, 0.2); }
  .header.primary .text {
    font-size: 2.2rem;
    display: inline-block;
    margin: 0; }

.header.secondary {
  text-transform: uppercase;
  font-size: 1.7rem;
  color: #474747;
  margin-bottom: 2rem; }

.header.tertiary {
  text-transform: uppercase;
  color: white;
  background-color: #666666;
  font-size: 1.3rem;
  text-align: center;
  width: 100%;
  top: 7.2rem;
  left: 0;
  z-index: 1;
}

.header.centered {
  text-align: center; }

.hyperlink {
  color: red;
}

.drop-valid {
  background-color: #f5f5f5;
  cursor: copy !important;
}

.react-contextmenu {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  color: #373a3c;
  font-size: 16px;
  margin: 2px 0 0;
  min-width: 160px;
  outline: none;
  opacity: 0;
  padding: 5px 0;
  pointer-events: none;
  text-align: left;
  transition: opacity 250ms ease !important;
}
.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
}
.react-contextmenu-item {
  background: 0 0;
  border: 0;
  color: #373a3c;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  padding: 3px 20px;
  text-align: inherit;
  white-space: nowrap;
}
.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  color: #fff;
  background-color: #20a0ff;
  border-color: #20a0ff;
  text-decoration: none;
}
.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
  color: #878a8c;
}
.react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: inherit;
  margin-bottom: 3px;
  padding: 2px 0;
}
.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
}
.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
}
.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item::after {
  content: '▶';
  display: inline-block;
  position: absolute;
  right: 1px;
}
.example-multiple-targets::after {
  content: attr(data-count);
  display: block;
}

.ck.ck-content ul,
.ck.ck-content ul li {
  list-style-type: inherit;
}

.ck.ck-content ul {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 40px;
}

.ck.ck-content ol,
.ck.ck-content ol li {
  list-style-type: decimal;
}
.ck.ck-content ol {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 40px;
}

.case-info > * {
  line-height: 1;
}

.form-group {
  margin-bottom: 0 !important;
}

.padding0 {
  padding: 0;
}

.navbar-button {
  margin-right: 1rem;
}

.navbar-title {
  margin-left: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 54%;
}

.overflow-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Toastify {
  position: absolute;
  z-index: 10000;
}

.records-table-tabs {
  background-color: rgb(102, 102, 102);
  height: 100%;
  margin-right: 2rem;
  padding: 0.5rem 2rem;
  font-size: 1.3rem;
  cursor: pointer;
}

.records-table-tabs:hover {
  background-color: #b7b7b7;
}

.records-table-tabs.active {
  background-color: #b7b7b7;
  height: 100%;
}

#funders-records-table-header {
  padding: 0 1rem 0 0!important;
}

.tooltip {
  z-index: 11000;
}

figure .image {
  float: left;
}

figure span {
  float: right;
  text-align: right;
}

.marginTop0 {
  margin-top: 0 !important;
}

.nav-link {
  color: #00b050;
  text-decoration: none;
  position: relative;
  padding-bottom: 2px;
  transition: color 0.3s ease-in-out;
}

.nav-link:not(#account-dropdown)::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  height: 2px;
  width: 0;
  background-color: #00b050;
  transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
}

.nav-link:not(#account-dropdown):hover::after {
  width: 100%;
  left: 0;
}

.nav-link:hover {
  color: #007a33 !important; /* Slightly darker green on hover */
}

#account-dropdown::after {
  content: '';
  position: absolute;
  right: 5px;
  top: 60%;
  color: #00b050;
  transform: translateY(-50%);
}


.navbar-active {
  color: #00b050 !important;
}

.form-control p {
  margin-bottom: 0 !important;
}

.sun-editor {
 z-index: 0;
}

.tableFixHead          { overflow: auto; height: 100px; }
.tableFixHead thead th { position: sticky; top: 0; z-index: 1; }

/* Fix for small visual issue with bootstrap table and sticky header having text floating from behind the background */
.table th {
  border-top: 0 !important;
}

.react-table-footer {
  background-color: lightgrey;
  font-weight: bold;
}

@media (max-width: 992px) {
  .navbar-collapse {
    background-color: white !important;
    padding: 10px;
    text-align: right;
  }
}

.table-container {
  overflow-x: auto;
  max-width: 98vw;
}

/* When the ReactModal is open, disable the outer vertical scrollbar */
body.ReactModal__Body--open {
  overflow-y: hidden;
}

tbody td.padded-table-cells {
  white-space: normal;
}

.checkmark {
  font-size: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.case-form div.table-container {
  margin-top: -20px;
  margin-bottom: -30px;
}

.case-form-notes-content div.collapsible__content {
  margin-top: 0 !important;
}

.is-invalid div.case-form-select__control {
  border-color: #dc3545;
}

table tr:nth-child(2n) {
  background-color: #f0f0f0;
}

.se-wrapper-code {
  min-height: 500px !important;
}

.react-datepicker__header {
  padding-bottom: 5px;
}


.rdg-cell, .rdg-header-sort-name {
  padding: 0 7px;
}

.rdg-header-sort-name {
  display: flex;
  align-items: center;
  height: 75px;
}

div.rdg-cell[role="columnheader"], span.rdg-header-sort-name, div.rdg-cell[role="columnheader"] span {
  width: 100%;
}

.rdg-checkbox-label {
  content: normal;
  cursor: pointer;
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
  padding: 0;
  border: 0;
}

.rdg-checkbox-input {
  display: none;
  appearance: none;
  padding: 0;
}

.rdg-sort-arrow {
  float: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  margin-right: 5px;
}

.rdg-header-row .rdg-cell {
  line-height: 2;
  white-space: normal; /* Allows text to wrap */
  text-align: left; /* Optional: center align the text */
  overflow: hidden; /* Prevents overflow */
}

.rdg-cell:not([role="columnheader"]) {
  width: 100% !important;
  height: 45px;
}

select.rdg-text-editor {
  width: 100%;
  padding: 3px;
}

input.rdg-text-editor {
  padding: 0 7px;
}

.rdg-cell input {
  width: 100%;
  padding: 0 7px;
}

.rdg-editor-container {
  padding: 0;
}

select:disabled {
  color: #000; /* Set the font color to black (or your preferred color) */
  background-color: #f8f9fa; /* Optional: change background color */
  opacity: 1; /* Remove the default graying out */
  cursor: not-allowed; /* Optional: keep the 'not-allowed' cursor */
}

.dueDateCell {
  color: white;
  padding: 8px 5px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  border: 1px solid #999;
}

.dueDateCell.dueToday {
  background-color: rebeccapurple;
  border: none;
  box-shadow: 0 0 5px rgba(102, 51, 153, 0.8), 0 0 5px rgba(102, 51, 153, 0.8);
}

.dueDateCell.overdue {
  background-color: red;
  border: none;
  box-shadow: 0 0 5px rgba(255, 0, 0, 0.8), 0 0 5px rgba(255, 0, 0, 0.8);
}

.dueDateCell.completed, .dueDateCell.dueFuture {
  background-color: #00b050;
}

.tradingSummary {
  background: #fff;
  padding-bottom: 0.5rem;
}

.rpv-core__splitter {
  width: 10px !important;
  margin-right: 5px;
  cursor: ew-resize;
}

.tooltip-inner {
  max-width: none;
}

.prevNextButtonContainer {
  display: flex;
  gap: 1rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
}

.prevNextCaseButtonContainer {
  display: flex;
  gap: 1rem;
  position: absolute;
  right: 30%;
  height: 30px;
  width: auto;
}
